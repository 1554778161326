/*
 * Тут может быть много исключений. Есть ещё средний род, где нужно добавлять "всё...".
 * Ну и букв больше в массивах.
 * Но мы не хотим охватывать все случаи сейчас.
 * По мере надобности можно расширять словарь. Полный нам не нужен.
 */

const FEMININE_ENDINGS = ["а", "ия", "ь"];
const MASCULINE_ENDINGS = ["m", "ж", "ш"];

export const multiplyNoun = (noun: string) => {
  const nounLowerCased = noun.toLowerCase();
  if (hasEndingInArray(nounLowerCased, FEMININE_ENDINGS)) {
    return `Вся ${noun}`;
  } else if (hasEndingInArray(nounLowerCased, MASCULINE_ENDINGS)) {
    return `Весь ${noun}`;
  }
  return `Все ${noun}`;
};

const hasEndingInArray = (word: string, endings: string[]) => {
  return Boolean(endings.find((ending) => word.endsWith(ending)));
};
