import { FilterCategory } from "@/api/generatedTypes";

export const flatFirstCategoryLevel = (categories?: FilterCategory[]) => {
  if (!categories) return [];
  return categories.reduce((acc: FilterCategory[], rootCategory) => {
    if (rootCategory.children?.length) {
      const childrenNoDisabled = rootCategory.children
        .map((subCat) => {
          return {
            ...subCat,
            children: subCat.children?.filter(filterDisabled),
          };
        })
        .filter(filterDisabled);
      return [...acc, ...childrenNoDisabled];
    }
    return acc;
  }, []);
};

const filterDisabled = (category: FilterCategory) => {
  return !category.disabled;
};
