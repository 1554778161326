import classNames from "classnames";

import styles from "./IconButton.module.scss";

interface IconButtonProps {
  src: string;
  alt: string;
  onClick(): void;
  className?: string;
}

const IconButton = ({ src, alt, onClick, className = "" }: IconButtonProps) => {
  return (
    <button className={classNames(styles.root, className)} onClick={onClick}>
      <img src={src} alt={alt} />
    </button>
  );
};

export default IconButton;
