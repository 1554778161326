import axios from "axios";

import { FavoritesResponse } from "./generatedTypes";

export const FavoritesApi = {
  get: () => axios.get<FavoritesResponse>("/favorites"),
  add: (block_id?: string, colored_model_id?: string) =>
    axios.post<FavoritesResponse>("/favorites", {
      block_id,
      colored_model_id,
    }),
  delete: (id: number) => axios.delete<FavoritesResponse>(`/favorites/${id}`),
};
