import classNames from "classnames";
import { useRouter } from "next/router";

import { CardHeader } from "@/components/CardHeader";
import { useToggle } from "@/hooks/useToggle";

import { CartButton } from "../CartButton";
import { FavoritesHeaderButton } from "../FavoritesHeaderButton";
import { MenuButton } from "../MenuButton";
import { FilterSearchModal } from "../modals/FilterSearchModal";
import { Menu } from "../modals/Menu";
import { BackButton } from "../ui-components";
import { LogoGray } from "../ui-components/LogoGray";
import { SearchButton } from "../ui-components/SearchButton";
import styles from "./Header.module.scss";

interface HeaderProps {
  backButtonHandler?(): void;
  isBackButton?: boolean;
  isProfileButton?: boolean;
  className?: string;
  logoClickHandler?(): void;
  title?: string;
  isCartButton?: boolean;
}

const Header = ({
  backButtonHandler,
  isBackButton = false,
  isProfileButton = false,
  className = "",
  logoClickHandler,
  title,
  isCartButton = true,
}: HeaderProps) => {
  const router = useRouter();

  const { isOpen: isMenuOpen, open: openMenu, close: closeMenu } = useToggle();
  const {
    isOpen: isFilterSearchModalOpen,
    open: openFilterSearchModal,
    close: closeFilterSearchModal,
  } = useToggle();
  return (
    <>
      <CardHeader
        className={classNames(styles.header, className)}
        centerSlotClassName={
          isBackButton
            ? styles.headerCenter
            : styles.headerCenterWithoutBackButton
        }
        rightSlotClassName={styles.headerRight}
        leftSlot={
          isBackButton ? (
            <BackButton
              onClick={backButtonHandler ? backButtonHandler : router.back}
            />
          ) : undefined
        }
        centerSlot={
          <>
            <LogoGray onClick={logoClickHandler} />
            {title && <div className={styles.title}>{title}</div>}
          </>
        }
        rightSlot={
          <>
            <SearchButton onClick={openFilterSearchModal} />
            {isCartButton && <CartButton />}
            {isCartButton && <FavoritesHeaderButton />}
            {isProfileButton && <MenuButton onClick={openMenu} />}
          </>
        }
      />
      <Menu isOpen={isMenuOpen} close={closeMenu} />
      <FilterSearchModal
        isOpen={isFilterSearchModalOpen}
        close={closeFilterSearchModal}
      />
    </>
  );
};

export default Header;
