import classNames from "classnames";

import { FilterCategory, FilterCollection } from "@/api/generatedTypes";
import { ITEM_TYPES } from "@/components/CategoryTree/CategoryTree";
import { useFilterHandlers } from "@/components/filter/useFilterHandlers";
import { multiplyNoun } from "@/helpers/multiplyNoun";

import styles from "./CategoryTreeItem.module.scss";

const CHILDREN_HEIGHT = 22;
const V_PADDINGS = 20;

const HIGHLIGHTED_ITEMS = ["Распродажа"];

interface CategoryTreeItemProps {
  category: FilterCategory | FilterCollection;
  opened?: boolean;
  toggle(id: string): void;
  itemType?: ITEM_TYPES;
  minChildrenAmountToShow?: number;
}

const CategoryTreeItem = ({
  category,
  opened = false,
  toggle,
  itemType = ITEM_TYPES.CATEGORY,
  minChildrenAmountToShow = 1,
}: CategoryTreeItemProps) => {
  const hasChildren =
    Number(category.children?.length) > minChildrenAmountToShow;

  const { applyCategories, applyCollections } = useFilterHandlers();
  const onClick = () => {
    switch (itemType) {
      case ITEM_TYPES.CATEGORY: {
        applyCategories([category.id]);
        break;
      }
      case ITEM_TYPES.COLLECTION: {
        applyCollections([category.id]);
        break;
      }
    }
  };

  const handleTogglerClick = () => {
    toggle(category.id);
  };

  const subListStyles =
    hasChildren && opened
      ? {
          maxHeight: `${
            (Number(category.children?.length) + 1) * CHILDREN_HEIGHT +
            V_PADDINGS
          }px`,
        }
      : {};

  return (
    <li
      className={classNames(styles.item, {
        [styles.opened]: opened,
        [styles.highlighted]: HIGHLIGHTED_ITEMS.includes(category.name),
      })}
    >
      {hasChildren ? (
        <>
          <span
            className={classNames(styles.link, styles.toggler)}
            onClick={handleTogglerClick}
          >
            {category.name}
          </span>
          <ul className={styles.subList} style={subListStyles}>
            <li className={styles.item}>
              <span className={styles.link} onClick={onClick}>
                {multiplyNoun(category.name)}
              </span>
            </li>
            {category.children?.map((childCategory) => (
              <CategoryTreeItem
                category={childCategory}
                key={childCategory.id}
                toggle={toggle}
                itemType={itemType}
              />
            ))}
          </ul>
        </>
      ) : (
        <span className={styles.link} onClick={onClick}>
          {category.name}
        </span>
      )}
    </li>
  );
};

export default CategoryTreeItem;
