import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import Link from "next/link";

import { FavoritesApi } from "@/api/FavoritesApi";

import { FavoritesIcon } from "../icons";
import styles from "./FavoritesHeaderButton.module.scss";

interface FavoritesHeaderButtonProps {
  className?: string;
}

const FavoritesHeaderButton = ({ className }: FavoritesHeaderButtonProps) => {
  const favoritesQuery = useQuery(["favorites"], FavoritesApi.get);
  const favoritesCount = favoritesQuery.data?.data.length || 0;

  if (!favoritesCount) return null;

  return (
    <Link
      href="/cart-favorites?index=1"
      className={classNames(styles.root, className)}
    >
      <FavoritesIcon />
      {favoritesCount && <p className={styles.counter}>{favoritesCount}</p>}
    </Link>
  );
};

export default FavoritesHeaderButton;
