import axios from "axios";

import {
  Cart,
  CartCountResponse,
  RequestCartAdd,
  RequestCartUpdate,
} from "@/api/generatedTypes";

const CartApi = {
  get: () => axios.get<Cart>("/cart"),

  changeSize: (cartItemId: number, newSizeId: string) => {
    const data: RequestCartUpdate = {
      scm_id: newSizeId,
    };
    return axios.put<Cart>(`/cart/${cartItemId}`, data);
  },

  delete: (cartItemId: number) => axios.delete<Cart>(`/cart/${cartItemId}`),

  add: (productId: string) => {
    const data: RequestCartAdd = {
      scm_id: productId,
    };
    return axios.post<Cart>(`cart`, data);
  },

  count: () => axios.get<CartCountResponse>("cart/count"),
};

export default CartApi;
