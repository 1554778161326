import classNames from "classnames";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import { parseCookies } from "nookies";
import { useEffect, useState } from "react";

import { CategoryTree } from "@/components/CategoryTree";
import { ITEM_TYPES } from "@/components/CategoryTree/CategoryTree";
import { FilterSearchModal } from "@/components/modals/FilterSearchModal";
import { LogoGray } from "@/components/ui-components/LogoGray";
import { SearchButton } from "@/components/ui-components/SearchButton";
import useMenuItems from "@/hooks/useMenuItems";
import { useToggle } from "@/hooks/useToggle";

import { ModalContainer } from "../ModalContainer";
import styles from "./Menu.module.scss";

const DynamicCitySelect = dynamic(
  () => import("@/components/CitySelect/CitySelect"),
  {
    ssr: false,
  }
);

interface MenuProps {
  isOpen: boolean;
  close(): void;
}

const Menu = ({ isOpen, close }: MenuProps) => {
  const [expandedItem, setExpandedItem] = useState("");
  const router = useRouter();

  const { categories, collections } = useMenuItems();

  const {
    isOpen: isFilterSearchModalOpen,
    open: openFilterSearchModal,
    close: closeFilterSearchModal,
  } = useToggle();

  useEffect(() => {
    router.events.on("routeChangeStart", close);

    return () => {
      router.events.off("routeChangeStart", close);
    };
  }, [router]);

  const { role } = parseCookies();

  const profileLink = role === "guest" ? "/auth" : "/profile";

  const setPreviousUrl = () => {
    window.localStorage.setItem("before_auth_url", router.asPath);
  };

  const toggleExpand = (id: string) =>
    setExpandedItem((prevState) => {
      if (prevState === id) return "";
      return id;
    });

  return (
    <>
      <ModalContainer isOpen={isOpen} containerClassName={styles.root}>
        <div className={styles.topBar}>
          <div className={styles.controls}>
            <SearchButton
              onClick={openFilterSearchModal}
              className={styles.searchButton}
            />
            <LogoGray />
            <button className={styles.closeButton} onClick={close}>
              <img src="/static/img/icons-v6/cross.svg" alt="close button" />
            </button>
          </div>

          <div className={styles.justify}>
            <DynamicCitySelect />
            <div
              className={classNames(styles.infoToggle, {
                [styles.opened]: expandedItem === "ИНФО",
              })}
              onClick={() => toggleExpand("ИНФО")}
            >
              ИНФО
            </div>
            <ul className={styles.infoMenu}>
              <li>
                <Link href="/articles/oplata-i-dostavka">доставка</Link>
              </li>
              <li>
                <Link href="/cart-favorites?index=1">избранное</Link>
              </li>
              <li>
                <Link href="/articles/contacts">контакты</Link>
              </li>
              <li>
                <Link href="/articles/oplata-i-dostavka">оплата</Link>
              </li>
              <li>
                <Link href="/stores">магазины</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className={styles.content}>
          <CategoryTree
            categories={collections}
            toggle={toggleExpand}
            expandedItem={expandedItem}
            itemsType={ITEM_TYPES.COLLECTION}
            minChildrenAmountToShow={0}
          />

          <CategoryTree
            categories={categories}
            toggle={toggleExpand}
            expandedItem={expandedItem}
          />
        </div>

        <div className={styles.bottomBar}>
          <div className={styles.justify}>
            <Link
              href={profileLink}
              className={styles.profileLink}
              onClick={setPreviousUrl}
            >
              <img
                src="/static/img/icons-v6/profile.svg"
                alt="Личный кабинет"
              />
              <span>личный кабинет</span>
            </Link>

            <Link
              href="https://wa.me/79050221220"
              target="_blank"
              className={styles.chatLink}
            >
              ЧАТ
            </Link>
          </div>
        </div>
      </ModalContainer>

      <FilterSearchModal
        isOpen={isFilterSearchModalOpen}
        close={closeFilterSearchModal}
      />
    </>
  );
};

export default Menu;
