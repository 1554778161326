import { useState } from "react";
import { useSetRecoilState } from "recoil";

import { mainFilterOpennedState } from "@/components/filter/mainFilterOpennedState";
import { useFilterHandlers } from "@/components/filter/useFilterHandlers";
import { SearchModalHeader } from "@/components/SearchModalHeader";

import { ModalContainer } from "../ModalContainer";
import styles from "./FilterSearchModal.module.scss";

interface FilterSearchModalProps {
  isOpen: boolean;
  close(): void;
}

const FilterSearchModal = ({ isOpen, close }: FilterSearchModalProps) => {
  const [searchValue, setSearchValue] = useState<string | undefined>("");
  const setFilterOpenned = useSetRecoilState(mainFilterOpennedState);

  const { applySearch } = useFilterHandlers();

  const applySearchHandler = (
    e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    if (!searchValue) return;
    applySearch(searchValue);
    setFilterOpenned(false);
    close();
  };

  return (
    <ModalContainer isOpen={isOpen} containerClassName={styles.root}>
      <div className={styles.content}>
        <SearchModalHeader
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          applySearchHandler={applySearchHandler}
          close={close}
          placeholder="введите запрос"
          className={styles.header}
        />
        <div className={styles.resultsContainer}>
          <button onClick={applySearchHandler}>{searchValue}</button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default FilterSearchModal;
