import styles from "./MenuButton.module.scss";

interface MenuButtonProps {
  onClick(): void;
}

const MenuButton = ({ onClick }: MenuButtonProps) => {
  return (
    <button className={styles.root} onClick={onClick}>
      <img src="/static/img/icons-v6/menu.svg" alt="profile icon" />
    </button>
  );
};

export default MenuButton;
