import classNames from "classnames";
import { Dispatch, SetStateAction, useEffect } from "react";

import { useInputClearButton } from "@/hooks/useInputClearButton";

import { SearchIcon } from "../icons";
import { BackButton } from "../ui-components";
import { IconButton } from "../ui-components/IconButton";
import styles from "./SearchModalHeader.module.scss";

interface SearchModalHeaderProps {
  searchValue?: string;
  setSearchValue: Dispatch<SetStateAction<string | undefined>>;
  applySearchHandler?(
    e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>
  ): void;
  placeholder?: string;
  close(): void;
  className?: string;
}

const SearchModalHeader = ({
  searchValue = "",
  setSearchValue,
  applySearchHandler,
  placeholder = "",
  close,
  className = "",
}: SearchModalHeaderProps) => {
  const onClear = () => {
    setSearchValue("");
  };

  const {
    isClearButtonVisible,
    showClearButton,
    removeClearButton,
    inputRef,
    restoreFocus,
  } = useInputClearButton();

  useEffect(() => {
    restoreFocus();
  }, [searchValue]);

  return (
    <div className={classNames(styles.root, className)}>
      <BackButton className={styles.backButton} onClick={close} />
      <div className={styles.inputContainer}>
        <SearchIcon
          className={styles.searcIcon}
          color={searchValue ? "#676560" : "#BDBCBA"}
        />
        <form onSubmit={applySearchHandler}>
          <input
            ref={inputRef}
            autoFocus
            className={styles.searchInput}
            placeholder={placeholder}
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            onFocus={showClearButton}
            onBlur={removeClearButton}
          />
        </form>
      </div>
      {isClearButtonVisible && (
        <IconButton
          className={styles.clearText}
          src="/static/img/icons-v6/cross.svg"
          alt="clear button"
          onClick={() => {
            onClear();
            restoreFocus();
          }}
        />
      )}
    </div>
  );
};

export default SearchModalHeader;
