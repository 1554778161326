import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import Link from "next/link";

import CartApi from "@/api/CartApi";

import styles from "./CartButton.module.scss";

interface CartButton {
  className?: string;
}

const CartButton = ({ className = "" }: CartButton) => {
  const cartCountQuery = useQuery(["cartCount"], CartApi.count);

  const count = cartCountQuery.data?.data.count;

  if (!count) return null;

  return (
    <Link href="/cart-favorites" className={classNames(styles.root, className)}>
      <img src="/static/img/icons-v6/cart.svg" alt="cart icon" />
      {count && <p className={styles.counter}>{count}</p>}
    </Link>
  );
};

export default CartButton;
