import { useQuery } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";

import FilterApi from "@/api/FilterApi";
import { FilterCategory, FilterCollection } from "@/api/generatedTypes";
import { selectedFiltersSelector } from "@/components/filter/selectedFiltersSelector";
import { flatFirstCategoryLevel } from "@/helpers/flatFirstCategoryLevel";

const useMenuItems = () => {
  const selectedFilters = useRecoilValue(selectedFiltersSelector);
  const filterQuery = useQuery(
    ["filterQuery", selectedFilters],
    () => FilterApi.filter(selectedFilters),
    {
      keepPreviousData: true,
    }
  );
  const rootCategories = filterQuery.data?.data.categories;
  const categories = flatFirstCategoryLevel(rootCategories);

  const collections =
    filterDisabledWithChildren(filterQuery.data?.data.collections) || [];

  return { categories, collections };
};

const filterDisabled = (category: FilterCategory | FilterCollection) => {
  return !category.disabled;
};

const filterDisabledWithChildren = (
  items?: FilterCollection[] | FilterCategory[]
) => {
  if (!items) return;
  return items
    .map((parent) => {
      if (!parent.children?.length) return parent;
      parent.children = parent.children.filter(filterDisabled);
      return parent;
    })
    .filter(filterDisabled);
};

export default useMenuItems;
