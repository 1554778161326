import Link from "next/link";
import { useResetRecoilState } from "recoil";

import { divScrollState } from "@/hooks/useDivScrollRestore/divScrollState";

interface LogoGrayProps {
  onClick?(): void;
}

const LogoGray = ({ onClick }: LogoGrayProps) => {
  const resetScroll = useResetRecoilState(divScrollState);

  return (
    <Link
      href="/"
      onClick={() => {
        resetScroll();
        onClick && onClick();
      }}
    >
      <img src="/static/img/icons-v6/logo-gray.svg" alt="LOOK ONLINE" />
    </Link>
  );
};

export default LogoGray;
