import { FilterCategory, FilterCollection } from "@/api/generatedTypes";
import { CategoryTreeItem } from "@/components/CategoryTreeItem";

import styles from "./CategoryTree.module.scss";

interface CategoryTreeProps {
  categories: FilterCategory[] | FilterCollection[];
  expandedItem: string;
  toggle(id: string): void;
  itemsType?: ITEM_TYPES;
  minChildrenAmountToShow?: number;
}

const CategoryTree = ({
  categories,
  expandedItem,
  toggle,
  itemsType = ITEM_TYPES.CATEGORY,
  minChildrenAmountToShow = 1,
}: CategoryTreeProps) => {
  if (!categories.length) return;

  return (
    <ul className={styles.root}>
      {categories.map((category) => (
        <CategoryTreeItem
          category={category}
          key={category.id}
          toggle={toggle}
          opened={category.id === expandedItem}
          itemType={itemsType}
          minChildrenAmountToShow={minChildrenAmountToShow}
        />
      ))}
    </ul>
  );
};

export enum ITEM_TYPES {
  CATEGORY,
  COLLECTION,
}

export default CategoryTree;
