import classNames from "classnames";

import { useLockBodyScrollModified } from "@/hooks/useLockBodyScrollModified";
import { useModalAppearing } from "@/hooks/useModalAppearing";

import { ModalPortal } from "../ModalPortal";
import styles from "./ModalContainer.module.scss";

interface ModalContainerProps {
  isOpen: boolean;
  containerClassName?: string;
  mountingClassName?: string;
  hidingClassName?: string;
  children: React.ReactNode;
}

const ModalContainer = ({
  isOpen,
  containerClassName = "",
  mountingClassName = "",
  hidingClassName = "",
  children,
}: ModalContainerProps) => {
  const { isVisible, mounting, hiding } = useModalAppearing({ isOpen });
  useLockBodyScrollModified(isOpen);

  if (!isVisible) return null;

  return (
    <ModalPortal>
      <div
        className={classNames(styles.root, containerClassName, {
          [styles.show]: mounting,
          [styles.hide]: hiding,
          [mountingClassName]: mounting,
          [hidingClassName]: hiding,
        })}
      >
        {children}
      </div>
    </ModalPortal>
  );
};

export default ModalContainer;
