import classNames from "classnames";

import { IconButton } from "../IconButton";
import styles from "./SearchButton.module.scss";

interface SearchButtonProps {
  onClick(): void;
  className?: string;
}

const SearchButton = ({ onClick, className = "" }: SearchButtonProps) => {
  return (
    <IconButton
      className={classNames(styles.root, className)}
      src="/static/img/icons-v6/search.svg"
      alt="search"
      onClick={onClick}
    />
  );
};

export default SearchButton;
